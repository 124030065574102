import app from 'flarum/app'
import { extend, override } from 'flarum/extend'
import ChangeEmailModal from 'flarum/components/ChangeEmailModal'
import LogInButtons from 'flarum/components/LogInButtons'
import LogInModal from 'flarum/components/LogInModal'
import Button from 'flarum/components/Button'
import HeaderSecondary from 'flarum/components/HeaderSecondary'
import SignUpModal from 'flarum/components/SignUpModal'
import Stream from 'flarum/utils/Stream'

function signInOrSignUp () {
  const width = 600
  const height = 550
  const $window = window.$(window)
  const isMobile = /Android|webOS|iPhone|iPad|BlackBerry/i.test(navigator.userAgent)
  const isWechat = /MicroMessenger/i.test(navigator.userAgent)
  const from = isWechat ? 'wechat' : ''
  if (isMobile && !isWechat) {
    return alert('请在微信中打开此页面')
  }
  window.open(app.forum.attribute('baseUrl') + '/auth/wechat?from=' + from, 'logInPopup',
    `width=${width},` +
    `height=${height},` +
    `top=${$window.height() / 2 - height / 2},` +
    `left=${$window.width() / 2 - width / 2},` +
    'status=no,scrollbars=no')
}

app.initializers.add('yuanli-utools', () => {
  // 当邮箱未验证时，无需输入密码
  override(ChangeEmailModal.prototype, 'content', function (content) {
    if (this.success) {
      return (
        <div className='Modal-body'>
          <div className='Form Form--centered'>
            <p className='helpText'>
              {app.translator.trans('core.forum.change_email.confirmation_message', { email: <strong>{this.email()}</strong> })}
            </p>
            <div className='Form-group'>
              <Button className='Button Button--primary Button--block' onclick={this.hide.bind(this)}>
                {app.translator.trans('core.forum.change_email.dismiss_button')}
              </Button>
            </div>
          </div>
        </div>
      )
    }
    return (
      <div className='Modal-body'>
        <div className='Form Form--centered'>
          <div className='Form-group'>
            <input
              type='email'
              name='email'
              className='FormControl'
              placeholder={app.session.user.email()}
              bidi={this.email}
              disabled={this.loading} />
          </div>
          {app.session.user.data.attributes.isEmailConfirmed ? <div className='Form-group'>
            <input
              type='password'
              name='password'
              className='FormControl'
              placeholder={app.translator.trans('core.forum.change_email.confirm_password_placeholder')}
              bidi={this.password}
              disabled={this.loading} />
          </div> : ''}
          <div className='Form-group'>
            {Button.component(
              {
                className: 'Button Button--primary Button--block',
                type: 'submit',
                loading: this.loading,
              },
              app.translator.trans('core.forum.change_email.submit_button')
            )}
          </div>
        </div>
      </div>
    )
  })

  // 微信扫码登录、微信浏览器内一键登录
  extend(LogInButtons.prototype, 'items', function (items) {

    const isMobile = /Android|webOS|iPhone|iPad|BlackBerry/i.test(navigator.userAgent)
    const isWechat = /MicroMessenger/i.test(navigator.userAgent)

    let loginBtn = <div><Button className='Button LogInButton--wechat' onclick={function () { signInOrSignUp() }} icon='wechat'>
      {app.translator.trans('yuanli-utools.forum.log_in.with_wechat_button')}
    </Button></div>
    if (isMobile && !isWechat) {
      loginBtn = <div className='wechatMini'>在微信中打开此页面，可一键登录</div>
    }
    items.add('wechat', loginBtn)
  })

  // 点击注册按钮时，弹出微信扫码
  override(LogInModal.prototype, 'signUp', () => {
    signInOrSignUp()
  })

  // 删除菜单栏中的注册链接，只允许先扫码后注册
  extend(HeaderSecondary.prototype, 'items', function (items) {
    items.remove('signUp')
  })

  // 新增 unionid，后端强制限制需要先扫码获得unionid
  override(SignUpModal.prototype, 'submitData', function () {
    const data = {
      username: this.username(),
      email: this.email(),
      unionid: Stream(this.attrs.unionid || '')
    };

    if (this.props.token) {
      data.token = this.props.token;
    } else {
      data.password = this.password();
    }

    return data;
  })
});
